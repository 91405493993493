import axios from 'axios';

const baseURL = process.env.VUE_APP_API_URL;


export const sendPhone = async (data) => {
    try {
        const response = await axios.post(`${baseURL}/sms_auth`, data);
        return response.data;
    } catch (error) {
        console.error('Помилка:', error);
        throw error;
    }
};

export const sendPassCode = async (authRequestId, code) => {
    try {
        const response = await axios.post(`${baseURL}/sms_auth/complete_sms_login/${authRequestId}`, {
            confirm_code: code
        });
        return response.data;
    } catch (error) {
        console.error('Помилка:', error);
        throw error;
    }
};

export const sendDataRegistration = async (authRequestId, data) => {
    try {
        const response = await axios.post(`${baseURL}/sms_auth/complete_signup/${authRequestId}`, {
            first_name: data.first_name,
            last_name: data.last_name,
            gender: data.gender,
            sms_code: data.sms_code,
            password: data.password,
        });
        return response.data;
    } catch (error) {
        console.error('Помилка:', error);
        throw error;
    }
};


export const getClientData = async (token) => {
    try {
        const response = await axios.get(`${baseURL}/me`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        return response.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
}
