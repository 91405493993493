<template>
  <div class="container mb-3">
    <div class="row">
      <div class="col-md-6 offset-md-3">
        <div class="text-center mt-5">
          <img src="@/assets/logo.png" alt="Логотип" class="img-fluid" width="400">
          <br />
          <br />
          <h2><b>Програма лояльності</b></h2>
          <br />
        </div>
        <div>
          <router-view />
        </div>
      </div>
    </div>
  </div>
</template>

<style></style>
<script setup>
</script>
