<template>
  <div class="container mt-2">
    <div class="row justify-content-center">
      <div class="card">
        <div class="card-body">
          <div class="col-lg-12 text-center">
            <div class="mt-4">
              <h4><b>Вхід</b></h4>
              <form @submit="submit" class="mt-3">
                <div class="col-lg-6 offset-lg-3">
                  <div class="form-group input-group-lg">
                    <input v-model="phone" type="tel" value="+380" v-maska data-maska="+380#########"
                      class="form-control" placeholder="+38(XХХ)-ХХХ-ХХ-ХХ" />
                  </div>
                  <div v-if="!isLoading" class="form-group input-group-lg mt-2">
                    <button style="width:100%;background-color: #3AB6A4;border-color: #3AB6A4;"
                      class="btn btn-warning btn-lg">Увійти</button>
                  </div>
                  <div v-else class="form-group input-group-lg mt-2">
                    <button style="width:100%;background-color: #3AB6A4;border-color: #3AB6A4;"
                      class="btn btn-warning btn-lg" disabled>
                      <span class="spinner-border spinner-border-lg" role="status" aria-hidden="true"></span>
                      <span class="visually-hidden">Loading...</span>
                    </button>
                  </div>
                </div>
                <div class="col-lg-12 mt-5">
                  <div class="button-container">
                    <a class="telegram-login-button" href="https://t.me/universal_park_bot">
                      <img width="25" src="@/assets/telegram.png">
                      Увійти через Telegram бота
                    </a>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import { sendPassCode, sendPhone } from '@/js/api';
import { vMaska } from "maska"
import { useAuthStore } from "@/stores/auth";
import { useRouter } from "vue-router";


export default {
  data() {
    return {
      phoneNumber: '',
      passCode: '',
      checkReg: null,
      authRequestId: null,
      success: null,
      token: null,
      clientData: null,
    };
  },
  directives: { maska: vMaska },
  setup() {
    const phone = ref('');
    const store = useAuthStore();
    const router = useRouter();
    const isLoading = ref(false);
    const submit = (e) => {
      e.preventDefault();

      isLoading.value = true;


      const number = phone.value;

      setTimeout(() => {
        store.sendAuthRequest(number).then((response) => {
          const data = response.data;
          router.push({ name: 'LoginConfirm', params: { auth_request_id: data.auth_request_id } });
          isLoading.value = false;
        });
      }, 1500);
    }

    return {
      phone,
      submit,
      isLoading,
    }
  },
  methods: {
    async sendPhoneNumber() {
      try {
        const data = {
          phone: this.phoneNumber
        };
        const response = await sendPhone(data);
        console.log(response);
        console.log(response.need_signup);
        this.authRequestId = response.auth_request_id;
        localStorage.setItem('authRequestId', this.authRequestId);
        console.log(this.authRequestId);
        if (response.need_signup === true) {
          this.checkReg = null;
          console.log(this.checkReg);
          this.goToSignUp();
        }
        if (response.need_signup === false) {
          this.checkReg = 3;
          console.log(this.checkReg);
        }
      } catch (error) {
        console.error(error);
      }
    },
    async checkPassCode() {
      try {
        const code = this.passCode;
        if (!this.authRequestId) {
          console.error('Id -');
          return;
        }
        const response = await sendPassCode(this.authRequestId, code);
        console.log(response);
        this.success = response.success;
        if (this.success === true) {
          this.token = response.token;
          localStorage.setItem('token', this.token);
          console.log(this.token);
          this.getData();
        }
      } catch (error) {
        console.error(error);
      }
    },
    async getData() {
      try {
        // const token = this.token;
        // const response = await getClientData(token);
        // console.log(response);
        // this.clientData = response;
        // this.checkReg = 5;
        // this.$nextTick(() => {
        //   const barcodeValue = response.barcode;
        //   const options = {
        //     format: 'CODE128',
        //     displayValue: true,
        //   };
        //   JsBarcode(this.$refs.barcode, barcodeValue, options);
        // });
        this.$router.push({ path: '/info' });
      } catch (error) {
        console.error(error);
      }
    },
    goToSignUp() {
      localStorage.setItem('alert', true);
      localStorage.setItem('phone', this.phoneNumber);
      localStorage.setItem('authRequestId', this.authRequestId);
      this.$router.push({ path: '/sign-up' });
    },
    filterInput() {
      let phoneNumber = this.phoneNumber.replace(/\D/g, '');
      if (phoneNumber.startsWith('+380')) {
      } else if (phoneNumber.startsWith('380')) {
        phoneNumber = '+' + phoneNumber;
      } else {
        phoneNumber = '+380' + phoneNumber;
      }

      this.phoneNumber = phoneNumber;
    },
    redirectToTg() {
      window.location.href = process.env.VUE_APP_BOT_URL;
    }
  },
};
</script>
