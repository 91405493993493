import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import VOtpInput from "vue3-otp-input";
import {createPinia} from 'pinia'
import '@/assets/style.css'
import 'bootstrap//dist/css/bootstrap.min.css'

import firebase from "firebase/compat/app";
import "firebase/compat/messaging";

const pinia = createPinia();

firebase.initializeApp({
    apiKey: "AIzaSyAVOtheGivlWxMSL16q189nfPWUhLQsXVc",
    authDomain: "sales-tools-ua.firebaseapp.com",
    projectId: "sales-tools-ua",
    storageBucket: "sales-tools-ua.appspot.com",
    messagingSenderId: "825611670665",
    appId: "1:825611670665:web:84e7f2890dad2d4cdba258",
    measurementId: "G-QD94X4JVCR"
});

const messaging = firebase.messaging();

messaging.onMessage(function(payload) {
    console.log("Message received. ", payload);

    const data = payload.notification;

    let notificationOptions = {
        body: data.body,
        icon: data.icon
    }
    let notif = new Notification(data.title, notificationOptions);

    notif.onclick = () => {
        console.log('Notification clicked');
    }
});
export const askForPermissionToReceiveNotifications = async () => {

    try {
        return messaging.getToken();
    } catch (error) {
        console.error(error);
    }
}


createApp(App)
    .use(pinia)
    .use(router)
    .component('v-otp-input', VOtpInput)
    .mount('#app')
