<template>
  <div class="row">
    <div v-if="clientData" class="card mb-3" style="
             padding: 10px 10px 10px 10px;
             border: #3AB6A4 3px solid;
             margin-top: 40px;">
      <button @click="logout" class="btn btn-outline-danger"
        style="position: absolute; top: 10px; right: 10px;">Вийти</button>
      <div class="card-body">
        <h2 class="text-center mb-4" style="color: black;">Дані клієнта</h2>
        <div class="mb-1">
          <span>Ім'я : <a style="font-weight: 700;">
              <span v-if="clientData.name != ''">
                {{ clientData.name }}
              </span>
              <span v-else>
                Веб-клієнт
              </span>
            </a>
          </span>
        </div>
        <div class="mb-1">
          <span>Номер телефону : <a style="font-weight: 700;">{{ clientData.username }}</a></span>
        </div>
        <div class="mb-1">
          <span>Баланс : <a style="color: green;font-weight: 700;">{{ clientData.balance / 100 }}
              UAH</a></span>
        </div>
        <div class="text-center">
          <svg ref="barcode"></svg>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getClientData } from '@/js/api';
import JsBarcode from 'jsbarcode';

export default {
  data() {
    return {
      clientData: null,
      token: null,
    }
  },
  mounted() {
    this.token = localStorage.getItem('token');
    if (this.token) {
      this.getData();
      console.log(this.token);
    } else {
      console.log("Токен не отримано", this.token)
    }
  },
  methods: {
    async getData() {
      try {
        const token = this.token;
        const response = await getClientData(token);
        this.clientData = response;
        if (this.clientData.location) {
          console.log(this.clientData);
          this.$nextTick(() => {
            const barcodeValue = response.barcode;
            const options = {
              format: 'CODE128',
              displayValue: true,
            };
            JsBarcode(this.$refs.barcode, barcodeValue, options);
          });
        }
      } catch (error) {

        console.error(error);
      }
    },
    logout() {
      localStorage.removeItem('token');
      this.$router.push({ path: '/login' });
    },
  },
}

</script>
