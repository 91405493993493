<template>
  <div>
    <div class="card">
      <div class="card-body">
        <div class="col-lg-12">
          <div>
            <div class="mt-2 text-center">
              <h4><b>Введіть код з SMS</b></h4>
            </div>
            <form @submit="submit" class="mt-3">
              <div style="display: flex; flex-direction: row" class="mt-3">
                <v-otp-input input-classes="otp-input has-error" :conditionalClass="['one', 'two', 'three', 'four']"
                             separator="-" inputType="letter-numeric" :num-inputs="4" :should-auto-focus="true"
                             :should-focus-order="true" @on-change="handleOnChange" @on-complete="handleOnComplete"
                             :placeholder="['*', '*', '*', '*']" :value="code" />
              </div>
              <div class="mt-5 text-center" v-if="codeInvalidError">
                <span class="text-danger"><b>SMS-код невірний</b></span>
              </div>
              <div class="mt-5 text-center" v-if="codeEmptyError">
                <span class="text-danger"><b>Введіть sms-код</b></span>
              </div>
              <div class="mt-5">
                <button style="width:100%;background-color: #3AB6A4;border-color: #3AB6A4;"
                        class="btn btn-warning btn-lg">Далі</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useAuthStore } from "@/stores/auth";
import { askForPermissionToReceiveNotifications} from "@/main";

export default {
  setup() {
    const route = useRoute();
    const router = useRouter();
    const store = useAuthStore();
    const code = ref('');
    const codeInvalidError = ref(false);
    const codeEmptyError = ref(false);

    const urlBase64ToUint8Array = (base64String)  =>{
      const padding = '='.repeat((4 - base64String.length % 4) % 4);
      const base64 = (base64String + padding)
          .replace(/-/g, '+')
          .replace(/_/g, '/');

      const rawData = window.atob(base64);
      const outputArray = new Uint8Array(rawData.length);

      for (let i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i);
      }

      return outputArray;
    }

    const handleOnChange = (e) => {
      code.value = e;
    }

    const handleOnComplete = (e) => {
      code.value = e;
    }
    const submit = (e) => {
      e.preventDefault();

      codeInvalidError.value = false;
      codeEmptyError.value = false;

      if (code.value) {
        store.checkCode(route.params.auth_request_id, code.value).then((response) => {
          const data = response.data;

          // eslint-disable-next-line no-empty
          if (data.success === true) {
            store.saveToken(data.token);

            askForPermissionToReceiveNotifications().then((token) => {
              store.subscribeWebPush(token);
            })

            /*Notification.requestPermission().then(permission => {
              if (permission === 'granted') {

                const publicKey = 'BICBncauQ6jC2i0xy1TGnLkMFORLdwD26IMLOfYILtpdZGlImGWTfqxuQ7X8fSsp8icViythBzsPgu4b5viFXqI';
                const applicationServerKey = urlBase64ToUint8Array(publicKey);

                navigator.serviceWorker.register('/sw.js').then(registration => {
                  registration.pushManager.subscribe({
                    userVisibleOnly: true,
                    applicationServerKey: applicationServerKey
                  }).then(subscription => {

                    const pushData = subscription.toJSON();

                    if(!pushData.options) {

                      store.subscribeWebPush({
                        endpoint: pushData.endpoint,
                        auth: pushData.keys.auth,
                        p256dh: pushData.keys.p256dh,
                      });

                    } else {
                      store.updateWebPush({
                        endpoint: pushData.endpoint,
                      });
                    }
                  }).catch(error => {
                    console.error('Ошибка подписки на уведомления:', error);
                  });
                });
              }
            });*/

            router.push({ name: 'Index' })

          } else {
            codeInvalidError.value = true;
          }

        })
      } else {
        codeEmptyError.value = true;
      }

    }

    return {
      submit,
      code,
      handleOnChange,
      handleOnComplete,
      codeInvalidError,
      codeEmptyError
    }
  }
}
</script>

<style>
.otp-input {
  width: 100%;
  height: 40px;
  padding: 5px;
  margin: 0 10px;
  font-size: 20px;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  text-align: center;
}

/* Background colour of an input field with value */
.otp-input.is-complete {
  background-color: #e4e4e4;
}

.otp-input::-webkit-inner-spin-button,
.otp-input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input::placeholder {
  font-size: 15px;
  text-align: center;
  font-weight: 600;
}
</style>
