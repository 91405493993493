// Import the necessary modules
import { defineStore } from 'pinia';
import axios from 'axios';

export const useAuthStore = defineStore("auth", () => {
    function sendAuthRequest(phone) {
        return axios.post(
            `${process.env.VUE_APP_API_URL}/sms_auth`,
            {
                phone: phone
            }
        );
    }

    function checkCode(authRequestId, code) {
        return axios.post(
            `${process.env.VUE_APP_API_URL}/sms_auth/complete_sms_login/${authRequestId}`,
            {
                confirm_code: code
            }
        );
    }

    function subscribeWebPush(token) {
        return axios.post(
            `${process.env.VUE_APP_API_URL}/me/subscribe_web_push`,
            {
                token: token,
            },
            {
                headers: {
                    'Authorization': `Bearer ${window.localStorage.token}`
                }
            }
        );
    }

    function updateWebPush(subscribe) {
        return axios.post(
            `${process.env.VUE_APP_API_URL}/me/update_web_push`,
            subscribe,
            {
                headers: {
                    'Authorization': `Bearer ${window.localStorage.token}`
                }
            }
        );
    }

    function saveToken(token) {
        window.localStorage.token = token;
    }

    function isLoggedIn() {
        return window.localStorage.token ?? false;
    }

    return {
        sendAuthRequest,
        checkCode,
        saveToken,
        isLoggedIn,
        subscribeWebPush,
        updateWebPush,
    };
});

