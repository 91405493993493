import { createRouter, createWebHistory } from 'vue-router'
import LoginPage from "@/views/LoginPage.vue";
import Info from '@/views/Info.vue'
import LoginConfirmPage from "@/views/LoginConfirmPage.vue";

const routes = [
  {
    path: '/',
    name: 'Index',
    component: Info,
    props: true
  },
  {
    path: '/login',
    name: 'Login',
    component: LoginPage,
    props: true
  },
  {
    path: '/login/:auth_request_id',
    name: 'LoginConfirm',
    component: LoginConfirmPage,
    props: true
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})


router.beforeEach((to, from, next) => {
  if (to.name === 'Index') {
    const token = localStorage.getItem('token');
    if (!token) {
      next({ name: 'Login' });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router
